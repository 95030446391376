<template>
  <v-chip
    v-bind="$attrs"
    :label="!dot" 
    :dark="dark"
    :color="flagData.color"
    class="px-2"
    :class="{ 'dot': dot }"
  >
    <v-icon
      v-if="icon"
      left
      small
      class="ml-0 mr-0"
      v-text="icon"
    />
    <span v-if="!dot">{{ flagData.text }}</span>
  </v-chip>
</template>
<script>
  export default {
    props: {
      flag: {
        type: String,
        default: '',
      },
      dark: {
        type: Boolean,
        default: true,
      },
      dot: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      dialog: false,
    }),
    computed: {
      flagData() {
        return this.$flags.find(x => x.value === this.flag.toLowerCase())
      },
    },
  }
</script>
<style>
  td .v-chip .v-chip__content,
  td .v-chip .v-chip__content span {
    width: 100%;
  }
  .v-chip.v-size--small.dot {
      width: 24px;
  }
</style>
<style scoped>
  td .v-chip {
    text-align: center;
    padding: 0 5px;
    width: 100%;
  }
</style>